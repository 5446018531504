<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :class="{active : modelValue}">
        <div class="loader_area" v-if="birthdaySettingLoader"><quote-loader/></div>
        <div class="global_setting" v-else>
            <button class="close_btn modal-window-close-button" @click="closeModel"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <button type="button" class="toggle_bar" @click="toggleSectionbar"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <div class="form-setting">
                    <div class="content_area">
                        <div class="content_wpr">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Celebrate Birthdays</h2>
                                    <label for="celebrate" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="celebrate" v-model="form.is_celebrate" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Send At</label>
                                                <div class="group-col-3">
                                                    <div class="field_wpr">
                                                        <multiselect v-model="form.greeting.send_at" :options="hours" :searchable="true" />
                                                    </div>
                                                    <div class="field_wpr">
                                                        <multiselect v-model="form.greeting.send_at_type" :options="clock" :searchable="true"></multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <label for="contact_timezone" class="switch_option capsule_btn m-0 border-0">
                                            <h5>Throttle for Contact Timezone</h5>
                                            <input type="checkbox" id="contact_timezone" v-model="form.greeting.throttle_for_contact_tz" :true-value="1" :false-value="0" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                                <div class="color_container mt-4">
                                    <label for="remind" class="switch_option capsule_btn m-0">
                                        <h5>Remind 3rd Party</h5>
                                        <input type="checkbox" id="remind" v-model="form.reminder.has_reminder" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div v-show="form.reminder.has_reminder == 1">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Send Before Birthday</label>
                                                    <div class="group-col-3">
                                                        <div class="field_wpr">
                                                            <input type="number" min="0" v-model="form.reminder.send_before" >
                                                        </div>
                                                        <div class="field_wpr">
                                                            <multiselect
                                                                v-model="form.reminder.send_before_type"
                                                                label="label"
                                                                value-prop="value"
                                                                :searchable="true"
                                                                :options="days">
                                                            </multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Send At</label>
                                                    <div class="group-col-3">
                                                        <div class="field_wpr">
                                                            <multiselect v-model="form.reminder.send_at" :options="hours" :searchable="true" />
                                                        </div>
                                                        <div class="field_wpr">
                                                            <multiselect v-model="form.reminder.send_at_type" :options="clock" :searchable="true"></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <label for="recipient_timezone" class="switch_option capsule_btn m-0">
                                                <h5>Throttle for Recipient Timezone</h5>
                                                <input type="checkbox" id="recipient_timezone" v-model="form.reminder.throttle_for_user_tz" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Recipient</label>
                                                    <div class="field_wpr" v-if="form.reminder.sending_method == 1 || form.reminder.sending_method == 3">
                                                        <input type="text" v-model="form.reminder.recipient_email" placeholder="Email Address">
                                                    </div>
                                                    <div class="field_wpr mt-1" v-if="form.reminder.sending_method == 2 || form.reminder.sending_method == 3">
                                                        <input type="text" v-model="form.reminder.recipient_mobile" placeholder="Mobile">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="section_wpr mb-1">
                                                <h3>Sending Method</h3>
                                            </div>
                                            <Form v-slot="{ errors }" ref="greetingForm">
                                                <notification-setting v-model="form.greeting" :errors="errors" type="greeting" ref="notification-greeting" />
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="action_wpr mb-0">
                        <button :disabled="loader" class="btn cancel_btn" @click="closeModel">Cancel</button>
                        <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmit"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
                    </div>
                </div>
            </div>
            <div class="preview_content">
                <div class="header">
                    <button class="close_btn" @click="toggleSectionbar"><i class="fas fa-times"></i></button>
                    <div class="section_header m-0">
                        <h2>Sending Method</h2>
                    </div>
                </div>
                <div class="content_area">
                    <Form v-slot="{ errors }" ref="reminderForm">
                        <notification-setting v-model="form.reminder" :errors="errors" type="reminder'" ref="notification-reminder" />
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'
    import { Form } from 'vee-validate'

    const NotificationSetting = defineAsyncComponent(() => import('@/pages/admin-setting/components/birthday/NotificationSetting'))

    export default {
        name: 'Birthday Setting',

        data() {
            return{
                hours: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12' ],
                clock: [ 'AM', 'PM' ],
                days: [
                    { value: '1', label: 'Minutes' },
                    { value: '2', label: 'Hours' },
                    { value: '3', label: 'Days' },
                ],
                form: {
                    is_celebrate: 0,
                    greeting: {
                        email_message: '',
                        email_subject: '',
                        sending_method: 1,
                        show_email_logo: 0,
                        has_logo: 0,
                        media: [],
                        logo_image: '',
                        show_email_signature: 0,
                        signature_id: 0,
                        sms_message: '',
                        send_at: 7,
                        send_at_type: 'AM',
                        throttle_for_contact_tz: 0,
                    },
                    reminder: {
                        email_message: '',
                        email_subject: '',
                        sending_method: 1,
                        show_email_logo: 0,
                        has_logo: 0,
                        media: [],
                        logo_image: '',
                        show_email_signature: 0,
                        signature_id: 0,
                        sms_message: '',
                        has_reminder: 0,
                        recipient_email: '',
                        recipient_mobile: '',
                        send_at: 7,
                        send_at_type: 'AM',
                        send_before: '',
                        send_before_type: ['1'],
                        throttle_for_user_tz: 0,
                    }
                },
                birthdaySettingLoader: false,
            }
        },

        props: {
            modelValue: Boolean,
        },

        emit: ['update:modelValue'],

        components:{
            NotificationSetting,
            Form,
        },

        watch: {
            birthdaySetting () {
                const vm = this;
                vm.resetForm();

                setTimeout(function () {
                    vm.birthdaySettingLoader = false;
                }, 50);
            },

            modelValue (value) {
                if (value) {
                    const vm = this;
                    document.body.classList.add('modal-open');

                    vm.getBirthdaySetting();
                    vm.birthdaySettingLoader = true;
                }else{
                    document.body.classList.remove('modal-open');
                }
            }
        },

        computed: mapState({
            user: state => state.authModule.user,
            birthdaySetting: state => state.commonModule.birthdaySetting,
            loader: state => state.commonModule.loader,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods:{
            ...mapActions ({
                getBirthdaySetting: 'commonModule/getBirthdaySetting',
                updateBirthdaySetting: 'commonModule/updateBirthdaySetting',
            }),

            closeModel () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                if (vm.birthdaySetting.greeting) {
                    vm.form = {
                        is_celebrate: vm.birthdaySetting.is_celebrate ? vm.birthdaySetting.is_celebrate : 0,
                        greeting: {
                            email_subject: vm.birthdaySetting.greeting.email_subject ? vm.birthdaySetting.greeting.email_subject : '',
                            email_message: vm.birthdaySetting.greeting.email_message ? vm.birthdaySetting.greeting.email_message : '',
                            has_logo: vm.birthdaySetting.greeting.has_logo ? vm.birthdaySetting.greeting.has_logo : 0,
                            logo_image: vm.birthdaySetting.greeting.logo_image ? vm.birthdaySetting.greeting.logo_image : '',
                            media: vm.birthdaySetting.greeting.media ? vm.birthdaySetting.greeting.media : [],
                            send_at: vm.birthdaySetting.greeting.send_at ? vm.birthdaySetting.greeting.send_at : 7,
                            send_at_type: vm.birthdaySetting.greeting.send_at_type ? vm.birthdaySetting.greeting.send_at_type : 'AM',
                            sending_method: vm.birthdaySetting.greeting.sending_method ? parseInt(vm.birthdaySetting.greeting.sending_method) : 1,
                            show_email_logo: vm.birthdaySetting.greeting.show_email_logo ? vm.birthdaySetting.greeting.show_email_logo : 0,
                            show_email_signature: vm.birthdaySetting.greeting.show_email_signature ? vm.birthdaySetting.greeting.show_email_signature : 0,
                            signature_id: vm.birthdaySetting.greeting.signature_id ? vm.birthdaySetting.greeting.signature_id : 0,
                            sms_message: vm.birthdaySetting.greeting.sms_message ? vm.birthdaySetting.greeting.sms_message : '',
                            throttle_for_contact_tz: vm.birthdaySetting.greeting.throttle_for_contact_tz ? vm.birthdaySetting.greeting.throttle_for_contact_tz : 0,
                        },
                        reminder: {
                            email_subject: vm.birthdaySetting.reminder.email_subject ? vm.birthdaySetting.reminder.email_subject : '',
                            email_message: vm.birthdaySetting.reminder.email_message ? vm.birthdaySetting.reminder.email_message : '',
                            has_logo: vm.birthdaySetting.reminder.has_logo ? vm.birthdaySetting.reminder.has_logo : 0,
                            has_reminder: vm.birthdaySetting.reminder.has_reminder ? vm.birthdaySetting.reminder.has_reminder : 0,
                            logo_image: vm.birthdaySetting.reminder.logo_image ? vm.birthdaySetting.reminder.logo_image : '',
                            media: vm.birthdaySetting.reminder.media ? vm.birthdaySetting.reminder.media : [],
                            recipient_email: vm.birthdaySetting.reminder.recipient_email ? vm.birthdaySetting.reminder.recipient_email : '',
                            recipient_mobile: vm.birthdaySetting.reminder.recipient_mobile ? vm.birthdaySetting.reminder.recipient_mobile : '',
                            send_at: vm.birthdaySetting.reminder.send_at ? vm.birthdaySetting.reminder.send_at : 7,
                            send_at_type: vm.birthdaySetting.reminder.send_at_type ? vm.birthdaySetting.reminder.send_at_type : 'AM',
                            send_before: vm.birthdaySetting.reminder.send_before ? vm.birthdaySetting.reminder.send_before : '',
                            send_before_type: vm.birthdaySetting.reminder.send_before_type ? [vm.birthdaySetting.reminder.send_before_type] : ['1'],
                            sending_method: vm.birthdaySetting.reminder.sending_method ? parseInt(vm.birthdaySetting.reminder.sending_method) : 3,
                            show_email_logo: vm.birthdaySetting.reminder.show_email_logo ? vm.birthdaySetting.reminder.show_email_logo : 0,
                            show_email_signature: vm.birthdaySetting.reminder.show_email_signature ? vm.birthdaySetting.reminder.show_email_signature : 0,
                            signature_id: vm.birthdaySetting.reminder.signature_id ? vm.birthdaySetting.reminder.signature_id : 0,
                            sms_message: vm.birthdaySetting.reminder.sms_message ? vm.birthdaySetting.reminder.sms_message : '',
                            throttle_for_user_tz: vm.birthdaySetting.reminder.throttle_for_user_tz ? vm.birthdaySetting.reminder.throttle_for_user_tz : 0,
                        }
                    }
                }
            },

            handleSubmit () {
                const vm = this;

                vm.$refs['reminderForm'].validate().then((result) => {
                    if (result.valid) {
                        if (vm.form.reminder.has_reminder == 1) {
                            vm.$refs['greetingForm'].validate().then((result) => {
                                if (result.valid) {
                                    vm.handleUpdateBirthday();
                                }
                            });
                        } else {
                            vm.handleUpdateBirthday();
                        }
                    }
                });
            },

            handleUpdateBirthday () {
                const vm = this;

                const greetingForm = vm.$refs['notification-greeting'];
                const reminderForm = vm.$refs['notification-reminder'];

                vm.form.greeting.email_subject        =  greetingForm.emailContent.subject;
                vm.form.greeting.email_message        =  greetingForm.emailContent.email;
                vm.form.greeting.show_email_logo      =  greetingForm.emailContent.show_email_logo;
                vm.form.greeting.logo_image           =  greetingForm.emailContent.email_logo ? greetingForm.emailContent.email_logo : '';
                vm.form.greeting.has_logo             =  greetingForm.emailContent.is_email_logo;
                vm.form.greeting.show_email_signature =  greetingForm.emailContent.is_signature;
                vm.form.greeting.signature_id         =  greetingForm.emailContent.signature_id;
                vm.form.greeting.sms_message          =  greetingForm.delivery_sms;
                vm.form.greeting.media                =  greetingForm.media;
                vm.form.greeting.sending_method       =  greetingForm.notif_method;
                vm.form.reminder.email_subject        =  reminderForm.emailContent.subject;
                vm.form.reminder.email_message        =  reminderForm.emailContent.email;
                vm.form.reminder.show_email_logo      =  reminderForm.emailContent.show_email_logo;
                vm.form.reminder.logo_image           =  reminderForm.emailContent.email_logo ? reminderForm.emailContent.email_logo : '';
                vm.form.reminder.has_logo             =  reminderForm.emailContent.is_email_logo;
                vm.form.reminder.show_email_signature =  reminderForm.emailContent.is_signature;
                vm.form.reminder.signature_id         =  reminderForm.emailContent.signature_id;
                vm.form.reminder.sms_message          =  reminderForm.delivery_sms;
                vm.form.reminder.media                =  reminderForm.media;
                vm.form.reminder.sending_method       =  reminderForm.notif_method;
                vm.form.reminder.send_before_type     =  typeof vm.form.reminder.send_before_type === 'object' ?  vm.form.reminder.send_before_type.join(',') : [vm.form.reminder.send_before_type];

                vm.updateBirthdaySetting(vm.form);
            },
            toggleSectionbar(){
                const ls_bar = document.querySelector('.global_setting');
                if(ls_bar.classList.contains('show_m_sidebar')){
                    ls_bar.classList.remove('show_m_sidebar');
                }else{
                    ls_bar.classList.add('show_m_sidebar');
                }
            },
        }
    }
</script>

<style scoped>
    .form-setting{
        display: flex;
        flex-direction: column;
        height: inherit;
    }
    .form-setting > .action_wpr{
        padding: 20px 45px;
        position: sticky;
        bottom: 0;
        left: 0;
        border-top: 1px solid #eee;
        margin-top: auto;
        background: #fafafb;
        z-index: 3;
    }
    .preview_content{
        background: #fff;
    }
    .preview_content :deep(.edit_section), .preview_content :deep(.top_area), .preview_content :deep(.additional_area), .preview_content :deep(.sms_comp){
        border: 1px solid #e9e9e9;
    }
    .preview_content .content_area{
        height: calc(100vh - 87px);
    }
    .global_setting .preview_content .close_btn{
        right: 5px;
        left: auto;
        top: 5px !important;
    }
    .preview_content :deep(.redactor-toolbar-wrapper){
        top: -30px;
    }
    @media(max-width: 1399px){
        .global_setting .preview_content{
            margin: 0 !important;
            width: 40% !important;
        }
    }
    @media(max-width: 991px){
        .global_setting .preview_content{
            width: 350px !important;
        }
    }
    @media(max-width: 767px){
        .global_setting .preview_content{
            width: 100% !important;
            height: calc(100vh - 85px);
            position: fixed;
            left: 0;
            right: 0;
            top: 100vh;
            background: #fff;
            border-radius: 20px 20px 0 0;
            box-shadow: 0 -2px 30px rgba(0, 0, 0, 0.15);
            z-index: 3;
            transition: all 0.3s ease-in-out;
        }
        .global_setting.show_m_sidebar .preview_content{
            top: 85px;
        }
        .global_setting .preview_content .header{
            padding: 20px 30px;
        }
        .preview_content .content_area{
            height: calc(100% - 70px);
        }
        .global_setting .tabs_content .section_content{
            padding: 20px 5px;
        }
    }
</style>
